var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "h-100dvh over-flow-y-scroll",
      attrs: { id: "register-" + _vm.layout }
    },
    [
      _c(
        "router-link",
        {
          staticClass: "p-absolute close-btn text-decoration-none",
          attrs: { to: "/" }
        },
        [
          _c("v-icon", { attrs: { size: "25", color: "white" } }, [
            _vm._v(" mdi-close ")
          ])
        ],
        1
      ),
      _c("div", { staticClass: "header-bg p-relative" }, [
        _c(
          "div",
          {
            staticClass:
              "logo d-flex justify-center p-absolute pa-6 rounded-circle"
          },
          [
            _vm.websiteLogo
              ? _c("v-img", {
                  attrs: {
                    src: _vm.baseImgUrl + _vm.websiteLogo,
                    "max-width": "40"
                  }
                })
              : _vm._e()
          ],
          1
        ),
        _c("img", {
          attrs: {
            src: require("@/assets/image/login-header-template3.png"),
            width: "100%"
          }
        })
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.form.veridate,
            callback: function($$v) {
              _vm.$set(_vm.form, "veridate", $$v)
            },
            expression: "form.veridate"
          }
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "px-1" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pb-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-text-field",
                        {
                          staticClass: "comment--text",
                          attrs: {
                            height: "40px",
                            rules: [
                              _vm.rules.require.acc,
                              _vm.rules.length.acc,
                              _vm.rules.format.acc
                            ],
                            placeholder: _vm.$t("form.missAccount"),
                            outlined: "",
                            autocapitalize: "off",
                            autocorrect: "off",
                            color: "secondary",
                            "background-color":
                              _vm.theme === "light" ? "white" : "black"
                          },
                          model: {
                            value: _vm.form.account,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "account", $$v)
                            },
                            expression: "form.account"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-1 px-2 comment--text",
                              attrs: { slot: "prepend-inner" },
                              slot: "prepend-inner"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("subHeader.account")) + " "
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "v-text-field",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            rules: [_vm.rules.require.pwd],
                            type: _vm.form.showPassword ? "text" : "password",
                            "append-icon": _vm.form.showPassword
                              ? "mdi-eye"
                              : "mdi-eye-off",
                            placeholder: _vm.$t("form.missPassword"),
                            "background-color":
                              _vm.theme === "light" ? "white" : "black",
                            outlined: "",
                            color: "secondary"
                          },
                          on: {
                            "click:append": function($event) {
                              _vm.form.showPassword = !_vm.form.showPassword
                            },
                            focus: _vm.showPwdTipText,
                            blur: _vm.hiddenPwdTipText
                          },
                          model: {
                            value: _vm.form.password,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "password", $$v)
                            },
                            expression: "form.password"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-1 px-2 text--text",
                              attrs: { slot: "prepend-inner" },
                              slot: "prepend-inner"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("subHeader.password")) + " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "append" },
                              on: {
                                click: function($event) {
                                  _vm.form.showPassword = !_vm.form.showPassword
                                }
                              },
                              slot: "append"
                            },
                            [
                              _c("Icon", {
                                staticClass: "text--text",
                                attrs: {
                                  data: _vm.form.showPassword
                                    ? _vm.eyeOpen
                                    : _vm.eyeClose,
                                  width: "24px",
                                  height: "24px"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "pa-0 ma-0 mb-2 pl-3 caption",
                          class: { "d-none": !_vm.showPwdTipStatus }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("form.errorPwdFormat", {
                                  min: _vm.pwdRules.size_min,
                                  max: _vm.pwdRules.size_max
                                })
                              ) +
                              " "
                          ),
                          _vm._l(_vm.pwdRules.requirement, function(
                            item,
                            index
                          ) {
                            return _c("span", { key: index }, [
                              _vm._v(" " + _vm._s(_vm.$t("form." + item)) + " ")
                            ])
                          })
                        ],
                        2
                      ),
                      _c(
                        "v-text-field",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            color: "secondary",
                            rules: [
                              _vm.rules.require.confirmPwd,
                              _vm.rules.verify.confirmPwd
                            ],
                            type: _vm.form.showPasswordConfirmation
                              ? "text"
                              : "password",
                            "background-color":
                              _vm.theme === "light" ? "white" : "black",
                            placeholder: _vm.$t("form.confirmPasswordAgain"),
                            outlined: ""
                          },
                          on: {
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.userLogin($event)
                            }
                          },
                          model: {
                            value: _vm.form.password_confirmation,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "password_confirmation", $$v)
                            },
                            expression: "form.password_confirmation"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-1 px-2 text--text",
                              attrs: { slot: "prepend-inner" },
                              slot: "prepend-inner"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("subHeader.password")) + " "
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "append" },
                              on: {
                                click: function($event) {
                                  _vm.form.showPasswordConfirmation = !_vm.form
                                    .showPasswordConfirmation
                                }
                              },
                              slot: "append"
                            },
                            [
                              _c("Icon", {
                                staticClass: "text--text",
                                attrs: {
                                  data: _vm.form.showPassword
                                    ? _vm.eyeOpen
                                    : _vm.eyeClose,
                                  width: "24px",
                                  height: "24px"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.registerValidationMethod === "sms"
                        ? _c(
                            "v-select",
                            {
                              attrs: {
                                items: _vm.phoneCountryCodeList,
                                "item-text": "country_label",
                                "item-value": "country_code",
                                label: _vm.$t("form.countryCodes"),
                                "single-line": "",
                                outlined: "",
                                disabled: _vm.disable.contact_no,
                                "background-color":
                                  _vm.theme === "light" ? "white" : "black"
                              },
                              model: {
                                value: _vm.phoneCountryCode,
                                callback: function($$v) {
                                  _vm.phoneCountryCode = $$v
                                },
                                expression: "phoneCountryCode"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mt-1 px-2 text--text",
                                  staticStyle: { width: "80px" },
                                  attrs: { slot: "prepend-inner" },
                                  slot: "prepend-inner"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("form.countryCodes")) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.registerValidationMethod === "sms"
                        ? _c(
                            "v-text-field",
                            {
                              staticClass: "comment--text",
                              attrs: {
                                height: "40px",
                                rules: [_vm.rules.require.mobile],
                                placeholder: _vm.$t("form.missMobile"),
                                outlined: "",
                                autocapitalize: "off",
                                autocorrect: "off",
                                color: "secondary",
                                "background-color":
                                  _vm.theme === "light" ? "white" : "black"
                              },
                              model: {
                                value: _vm.form.contact_no,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "contact_no", $$v)
                                },
                                expression: "form.contact_no"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mt-1 px-2 comment--text",
                                  attrs: { slot: "prepend-inner" },
                                  slot: "prepend-inner"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("myCenter.mobile")) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.registerValidationMethod === "sms"
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "px-0 caption mb-5",
                              attrs: {
                                disabled: _vm.disable.verifiMobileCode,
                                block: "",
                                outlined: "",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handlerVerifiMobileCode(
                                    "verifiMobileCode"
                                  )
                                }
                              }
                            },
                            [
                              _vm.verifiMobileCodeBtnSec === 60
                                ? [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "myCenter.sendVerificationCode"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                : [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.verifiMobileCodeBtnSec +
                                            "s " +
                                            _vm.$t("global.retry")
                                        ) +
                                        " "
                                    )
                                  ]
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.registerValidationMethod === "sms"
                        ? _c(
                            "div",
                            { staticClass: "p-relative" },
                            [
                              _c(
                                "v-text-field",
                                {
                                  attrs: {
                                    rules: [
                                      _vm.rules.require.missMobileVerifyCode,
                                      _vm.rules.length.missMobileVerifyCode
                                    ],
                                    placeholder: _vm.$t(
                                      "form.missMobileVerifyCode"
                                    ),
                                    outlined: "",
                                    "background-color":
                                      _vm.theme === "light" ? "white" : "black"
                                  },
                                  model: {
                                    value: _vm.form.verifiMobileCode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "verifiMobileCode",
                                        $$v
                                      )
                                    },
                                    expression: "form.verifiMobileCode"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt-1 px-2 text--text",
                                      attrs: { slot: "prepend-inner" },
                                      slot: "prepend-inner"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("form.missCaptcha")) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-text-field",
                        {
                          attrs: {
                            placeholder: _vm.$t("form.missAgentCode"),
                            outlined: "",
                            autocapitalize: "off",
                            "background-color":
                              _vm.theme === "light" ? "white" : "black",
                            autocorrect: "off",
                            disabled: _vm.agentCodeDisable
                          },
                          model: {
                            value: _vm.form.agentCode,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "agentCode", $$v)
                            },
                            expression: "form.agentCode"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-1 px-2 text--text",
                              attrs: { slot: "prepend-inner" },
                              slot: "prepend-inner"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("form.agentCode")) + " "
                              )
                            ]
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "p-relative" },
                        [
                          _c(
                            "v-text-field",
                            {
                              attrs: {
                                rules: [
                                  _vm.rules.require.verifyCode,
                                  _vm.rules.verify.verifyCode
                                ],
                                placeholder: _vm.$t("form.missVerifyCode"),
                                outlined: "",
                                "background-color":
                                  _vm.theme === "light" ? "white" : "black",
                                inputmode: "decimal"
                              },
                              model: {
                                value: _vm.form.verifyCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "verifyCode", $$v)
                                },
                                expression: "form.verifyCode"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "mt-1 px-2 text--text",
                                  attrs: { slot: "prepend-inner" },
                                  slot: "prepend-inner"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("form.missCaptcha")) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "identifyArea",
                              on: {
                                click: function($event) {
                                  return _vm.identifyReset(true)
                                }
                              }
                            },
                            [
                              _c("Identify", {
                                attrs: { "identify-code": _vm.identifyCode }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("span", { staticClass: "caption comment--text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("form.missVerifyCodeFormat")) +
                            " "
                        )
                      ]),
                      _c(
                        "v-col",
                        { staticClass: "py-0" },
                        [
                          _c(
                            "v-checkbox",
                            {
                              staticClass: "ma-0 chk-age",
                              attrs: { rules: [_vm.rules.require.age] },
                              model: {
                                value: _vm.form.checkAge,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "checkAge", $$v)
                                },
                                expression: "form.checkAge"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "caption secondary--text",
                                  attrs: { slot: "label" },
                                  slot: "label"
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm
                                            .$t("form.registerChecked", {
                                              name: _vm.websiteName
                                            })
                                            .substr(0, 11)
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "ml-1 caption link--text text-decoration-underline cursor-pointer",
                                      on: {
                                        click: function($event) {
                                          _vm.rulesDialog.status = true
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm
                                              .$t("form.registerChecked", {
                                                name: _vm.websiteName
                                              })
                                              .substr(11)
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "py-0 px-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                height: "44",
                                disabled: !_vm.form.veridate,
                                block: "",
                                color: "primary"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.handlerVerifiMobileCode(
                                    "VerificationCodeValidation"
                                  )
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("subHeader.register")) + " "
                              )
                            ]
                          ),
                          _vm.socialDisplay ? _c("MediaLogin") : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c(
                            "p",
                            { staticClass: "caption" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("registerAndLogin.switchLogin")
                                  ) +
                                  " "
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass:
                                    "text-decoration-none secondary--text",
                                  attrs: { to: "/login" }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("subHeader.login")))
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        { attrs: { dialog: _vm.dialog.status, width: 280 } },
        [
          _c(
            "span",
            { staticClass: "px-6", attrs: { slot: "text" }, slot: "text" },
            [_vm._v(_vm._s(_vm.dialog.text))]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", color: "danger", text: "" },
              on: {
                click: function($event) {
                  _vm.dialog.status = !_vm.dialog.status
                }
              },
              slot: "btn"
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", color: "primary", text: "" },
              on: {
                click: function($event) {
                  _vm.dialog.status = !_vm.dialog.status
                }
              },
              slot: "btn"
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.tryAgain")) + " ")]
          )
        ],
        1
      ),
      _vm.rulesDialog
        ? _c(
            "Dialog",
            { attrs: { width: 320, dialog: _vm.rulesDialog.status } },
            [
              _c(
                "span",
                {
                  staticClass: "subtitle-1 title--text",
                  attrs: { slot: "title" },
                  slot: "title"
                },
                [_vm._v(" " + _vm._s(_vm.rulesDialog.title) + " ")]
              ),
              _c("div", { attrs: { slot: "text" }, slot: "text" }, [
                _c("div", { staticClass: "px-6 rules__content" }, [
                  _c(
                    "ul",
                    { staticClass: "pl-0 caption text--text" },
                    _vm._l(_vm.rulesDialog.content, function(item, index) {
                      return _c("li", { key: index, staticClass: "pb-2" }, [
                        _vm._v(" " + _vm._s(item) + " ")
                      ])
                    }),
                    0
                  )
                ])
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "mb-2",
                  attrs: { slot: "btn", text: "", color: "primary" },
                  on: { click: _vm.rulesDialogClose },
                  slot: "btn"
                },
                [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "Dialog",
        { attrs: { dialog: _vm.isLogin, height: 140 } },
        [
          _c(
            "div",
            {
              staticClass: "px-6 text--text",
              attrs: { slot: "text" },
              slot: "text"
            },
            [_vm._v(" " + _vm._s(_vm.$t("flashMessage.isLoginStatus")) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mb-2 text--text",
              attrs: { slot: "btn", text: "" },
              on: {
                click: function($event) {
                  return _vm.$router.push({ path: "/" })
                }
              },
              slot: "btn"
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.cancel")) + " ")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "mb-2",
              attrs: { slot: "btn", text: "", color: "primary" },
              on: { click: _vm.userLogout },
              slot: "btn"
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: {
            dialog: _vm.registerFailDialog.status,
            width: 280,
            height: 140
          }
        },
        [
          _c(
            "div",
            { staticClass: "px-6", attrs: { slot: "text" }, slot: "text" },
            [_vm._v(" " + _vm._s(_vm.registerFailDialog.text) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", text: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.registerFailDialog.status = !_vm.registerFailDialog.status
                }
              },
              slot: "btn"
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.tryAgain")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }