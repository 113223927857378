var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vh-100", attrs: { id: "login" } },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.form.veridate,
            callback: function($$v) {
              _vm.$set(_vm.form, "veridate", $$v)
            },
            expression: "form.veridate"
          }
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "px-1" },
                [
                  _c(
                    "v-col",
                    { staticClass: "mt-5 mb-2", attrs: { cols: "12" } },
                    [
                      _c(
                        "div",
                        { staticClass: "logo d-flex justify-center" },
                        [
                          _vm.websiteLogo
                            ? _c("v-img", {
                                attrs: {
                                  src: _vm.baseImgUrl + _vm.websiteLogo,
                                  "max-width": "72"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      !_vm.websiteLogo
                        ? _c("h1", { staticClass: "text-center title--text" }, [
                            _vm._v(" " + _vm._s(_vm.websiteName) + " ")
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-1", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-text-field",
                        {
                          attrs: {
                            id: "acc",
                            rules: [_vm.rules.requiredAcc],
                            autofocus: "",
                            placeholder: _vm.$t("form.missAccount"),
                            outlined: "",
                            autocapitalize: "off",
                            autocorrect: "off",
                            filled: "",
                            dense: ""
                          },
                          model: {
                            value: _vm.form.userName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "userName", $$v)
                            },
                            expression: "form.userName"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-0",
                              attrs: { slot: "prepend-inner" },
                              slot: "prepend-inner"
                            },
                            [
                              _c("Icon", {
                                staticClass: "primary--text",
                                attrs: {
                                  data: require("@icon/bottomNav/profile.svg"),
                                  width: "24px",
                                  height: "24px"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-1", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-text-field",
                        {
                          attrs: {
                            id: "pwd",
                            rules: [_vm.rules.requiredPwd],
                            type: _vm.form.showPassword ? "text" : "password",
                            placeholder: _vm.$t("form.missPassword"),
                            outlined: "",
                            filled: "",
                            dense: ""
                          },
                          on: {
                            keydown: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.userLogin($event)
                            }
                          },
                          model: {
                            value: _vm.form.password,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "password", $$v)
                            },
                            expression: "form.password"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "mt-0",
                              attrs: { slot: "prepend-inner" },
                              slot: "prepend-inner"
                            },
                            [
                              _c("Icon", {
                                staticClass: "primary--text",
                                attrs: {
                                  data: require("@icon/password.svg"),
                                  width: "24px",
                                  height: "24px"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              attrs: { slot: "append" },
                              on: {
                                click: function($event) {
                                  _vm.form.showPassword = !_vm.form.showPassword
                                }
                              },
                              slot: "append"
                            },
                            [
                              _c("Icon", {
                                staticClass: "primary--text",
                                attrs: {
                                  data: _vm.form.showPassword
                                    ? _vm.eyeOpen
                                    : _vm.eyeClose,
                                  width: "24px",
                                  height: "24px"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "py-0", attrs: { id: "remember" } },
                    [
                      _c(
                        "v-checkbox",
                        {
                          staticClass: "mt-0 py-0",
                          attrs: { dense: "" },
                          model: {
                            value: _vm.form.rememberMe,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "rememberMe", $$v)
                            },
                            expression: "form.rememberMe"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "primary--text caption",
                              attrs: { slot: "label" },
                              slot: "label"
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("form.rememberMe")) + " "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pt-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            height: "44px",
                            disabled: !_vm.form.veridate,
                            block: "",
                            color: "primary"
                          },
                          on: { click: _vm.userLogin }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "btnText--text subtitle-1" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("subHeader.login")) + " "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm.socialDisplay ? _c("MediaLogin") : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pt-0 d-flex subtitle-2" },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "link--text caption cursor-pointer",
                          on: { click: _vm.forgotPassword }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("subHeader.forgetPassword")) +
                              " ? "
                          )
                        ]
                      ),
                      _c("v-spacer"),
                      _vm.registerStatus
                        ? _c(
                            "router-link",
                            {
                              staticClass: "text-decoration-none caption",
                              attrs: { to: "/register" }
                            },
                            [
                              _c("span", { staticClass: "title--text" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("registerAndLogin.noAccount") +
                                        " " +
                                        _vm.$t("global.please")
                                    ) +
                                    " "
                                )
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("registerAndLogin.switchRegister")
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "hang-out-btn w-100 text-center" },
        [
          _c(
            "router-link",
            { staticClass: "text-decoration-none", attrs: { to: "/" } },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    rounded: "",
                    depressed: "",
                    color: "rgba(234, 234, 234, 0.12)"
                  }
                },
                [
                  _c("span", { staticClass: "babyBlue--text subtitle-2" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("registerAndLogin.hangOut")) + " "
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        { attrs: { dialog: _vm.dialog.status, width: 280, height: 140 } },
        [
          _c(
            "div",
            { staticClass: "px-6", attrs: { slot: "text" }, slot: "text" },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("flashMessage.accountOrPwError")) + " "
              )
            ]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", text: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.dialog.status = !_vm.dialog.status
                }
              },
              slot: "btn"
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.tryAgain")) + " ")]
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { width: 280, dialog: _vm.forgotPasswordDialog.status },
          on: { "update-dialog": _vm.changeForgotDialog }
        },
        [
          _c(
            "div",
            {
              staticClass: "px-6 subtitle-1 font-weight-bold",
              attrs: { slot: "text" },
              slot: "text"
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.forgotPasswordDialog.resetStatus,
                      expression: "!forgotPasswordDialog.resetStatus"
                    }
                  ]
                },
                [
                  _c("p", { staticClass: "icon--text" }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("subHeader.forgetPassword")) + " "
                    )
                  ]),
                  _c(
                    "v-form",
                    {
                      ref: "verify1",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.forgotPasswordDialog.verify1,
                        callback: function($$v) {
                          _vm.$set(_vm.forgotPasswordDialog, "verify1", $$v)
                        },
                        expression: "forgotPasswordDialog.verify1"
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "title--text caption font-weight-medium"
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("subHeader.account")) + " "
                          )
                        ]
                      ),
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          placeholder: _vm.$t("form.missAccount"),
                          rules: [_vm.rules.requiredAcc],
                          "single-line": "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.forgotPasswordDialog.form.account,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.forgotPasswordDialog.form,
                              "account",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "forgotPasswordDialog.form.account"
                        }
                      }),
                      _vm.validateType === "sms"
                        ? _c(
                            "div",
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "title--text caption font-weight-medium"
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("form.verifySMS")) + " "
                                  )
                                ]
                              ),
                              _c("v-select", {
                                staticClass: "mt-2",
                                attrs: {
                                  items: _vm.phoneCountryCodeList,
                                  "item-text": "country_label",
                                  "item-value": "country_code",
                                  label: "Select",
                                  dense: "",
                                  "single-line": "",
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.phoneCountryCode,
                                  callback: function($$v) {
                                    _vm.phoneCountryCode = $$v
                                  },
                                  expression: "phoneCountryCode"
                                }
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  "forgot-password-dialog": "",
                                  placeholder: _vm.$t("form.missMobile"),
                                  rules: [_vm.rules.requiredPhone],
                                  "single-line": "",
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.forgotPasswordDialog.form.phone,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.forgotPasswordDialog.form,
                                      "phone",
                                      $$v
                                    )
                                  },
                                  expression: "forgotPasswordDialog.form.phone"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.validateType === "email"
                        ? _c(
                            "div",
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "title--text caption font-weight-medium"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("form.verifyEmail")) +
                                      " "
                                  )
                                ]
                              ),
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  placeholder: _vm.$t("form.missEmail"),
                                  rules: [
                                    _vm.rules.requiredEmail,
                                    _vm.rules.isEmail
                                  ],
                                  "single-line": "",
                                  outlined: ""
                                },
                                on: {
                                  keydown: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.sendVerifyCode($event)
                                  }
                                },
                                model: {
                                  value: _vm.forgotPasswordDialog.form.email,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.forgotPasswordDialog.form,
                                      "email",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "forgotPasswordDialog.form.email"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-0 caption",
                          attrs: { text: "", color: "primary", disable: true },
                          on: { click: _vm.sendVerifyCode }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("myCenter.sendVerificationCode")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.forgotPasswordDialog.resetStatus,
                      expression: "forgotPasswordDialog.resetStatus"
                    }
                  ]
                },
                [
                  _c("p", { staticClass: "icon--text" }, [
                    _vm._v(" " + _vm._s(_vm.$t("form.resetPassword")) + " ")
                  ]),
                  _c(
                    "v-form",
                    {
                      ref: "verify2",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.forgotPasswordDialog.verify2,
                        callback: function($$v) {
                          _vm.$set(_vm.forgotPasswordDialog, "verify2", $$v)
                        },
                        expression: "forgotPasswordDialog.verify2"
                      }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "title--text caption font-weight-medium"
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("form.missCaptcha")) + " ")]
                      ),
                      _c(
                        "v-container",
                        { staticClass: "py-0" },
                        [
                          _c(
                            "v-row",
                            {},
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-0",
                                  class:
                                    _vm.clientLang === "ph" ? "pb-0" : "pr-1",
                                  attrs: { cols: _vm.itemsWidth }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      placeholder: _vm.$t(
                                        "form.missVerifyCode"
                                      ),
                                      rules: [_vm.rules.requiredVerifyCode],
                                      "single-line": "",
                                      outlined: ""
                                    },
                                    model: {
                                      value:
                                        _vm.forgotPasswordDialog.form
                                          .verifyCode,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.forgotPasswordDialog.form,
                                          "verifyCode",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression:
                                        "forgotPasswordDialog.form.verifyCode"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "px-0",
                                  class:
                                    _vm.clientLang === "ph" ? "pt-0" : "pl-1",
                                  attrs: { cols: _vm.itemsWidth }
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "px-0 caption",
                                      attrs: {
                                        block: "",
                                        outlined: "",
                                        color: "primary",
                                        disabled:
                                          _vm.forgotPasswordDialog
                                            .resendVerifyCode.num < 60
                                      },
                                      on: { click: _vm.sendVerifyCode }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.forgotPasswordDialog
                                                  .resendVerifyCode.num === 60,
                                              expression:
                                                "forgotPasswordDialog.resendVerifyCode.num === 60"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("global.resend")) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.forgotPasswordDialog
                                                  .resendVerifyCode.num < 60,
                                              expression:
                                                "forgotPasswordDialog.resendVerifyCode.num < 60"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.forgotPasswordDialog
                                                  .resendVerifyCode.num +
                                                  "s " +
                                                  _vm.$t("global.retry")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "title--text caption font-weight-medium"
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("form.newPassword")) + " ")]
                      ),
                      _c("v-text-field", {
                        attrs: {
                          type: "password",
                          dense: "",
                          placeholder: _vm.$t("form.enterNewPassword"),
                          rules: [
                            _vm.rules.requiredNewPassword,
                            _vm.rules.newPasswordFormat
                          ],
                          "single-line": "",
                          outlined: ""
                        },
                        model: {
                          value: _vm.forgotPasswordDialog.form.newPassword,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.forgotPasswordDialog.form,
                              "newPassword",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "forgotPasswordDialog.form.newPassword"
                        }
                      }),
                      _c(
                        "div",
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "title--text caption font-weight-medium"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("form.newPasswordConfirm")) +
                                  " "
                              )
                            ]
                          ),
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              type: "password",
                              placeholder: _vm.$t("form.enterNewPasswordAgain"),
                              rules: [
                                _vm.rules.requiredNewPasswordConfirm,
                                _vm.rules.passwordConfirm
                              ],
                              "single-line": "",
                              outlined: ""
                            },
                            model: {
                              value:
                                _vm.forgotPasswordDialog.form
                                  .newPasswordConfirm,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.forgotPasswordDialog.form,
                                  "newPasswordConfirm",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "forgotPasswordDialog.form.newPasswordConfirm"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-0 subtitle-2 subtitle--text mr-2",
                          attrs: { text: "" },
                          on: { click: _vm.prevStep }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("form.prev")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "pa-0 subtitle-2",
                          attrs: { text: "", color: "primary" },
                          on: { click: _vm.resetPasswordSubmit }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("global.confirmation")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "Dialog",
        {
          attrs: {
            dialog: _vm.forgotPasswordDialog.resetFailDialog.status,
            width: 280,
            height: 140
          }
        },
        [
          _c(
            "div",
            { staticClass: "px-6", attrs: { slot: "text" }, slot: "text" },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.forgotPasswordDialog.resetFailDialog.content) +
                  " "
              )
            ]
          ),
          _c(
            "v-btn",
            {
              attrs: { slot: "btn", text: "", color: "primary" },
              on: {
                click: function($event) {
                  _vm.forgotPasswordDialog.resetFailDialog.content = ""
                }
              },
              slot: "btn"
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.confirmation")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }